import { COUNTRIES } from "../../constants/common/common";
import { constants } from "../../constants/pageConstants/pdpConstants";
import { InterceptorClient } from "../../interceptor/interceptorClient";
import {
  ANALYTICS_DATA_URL,
  AWS_PRE_SIGNED_URL,
  GET_BREADCRUMBS_DETAILS,
  CALENDER_MSG_URL,
  GET_PRODUCT_DETAILS_URL,
  GET_PRODUCT_REVIEWS_URL,
  GET_RECENTLY_VIEWED_BY_YOU_URL,
  GET_YOU_MAY_ALSO_LIKE_URL,
  OFFERS_DATA_URL,
  CUSTOMIZABLE_CONTENT_URL,
  WHAT_OTHERS_ARE_VIEWING_URL,
  GET_PRODUCT_STOCK_AVAILABLE_URL,
  OUT_OF_STOCK_URL,
  PRICE_SURGE_DETAILS_ENDPOINT,
  UPDATE_PRODUCT_DETAILS_URL,
  PDP_FOOTER_LINKS,
} from "./constants";

const webBaseUrl = process.env.NEXT_PUBLIC_BASE_URL;
const serverBaseUrl = process.env.NEXT_PUBLIC_SERVER_URL_API;

/**
 * Fetches product Details details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the product Details.
 */
export const getProductDetailsAPI = async (country = "india", urlIdentifier) => {
  const response = await InterceptorClient.getFTSInstance(serverBaseUrl).get(
    `${GET_PRODUCT_DETAILS_URL(country, urlIdentifier)}`,
    false,
  );
  return response;
};

/**
 * Fetches product Details details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the product Details.
 */
export const updateProductDetailsAPI = async (
  productId = "",
  currencyUomId = "INR",
  catalogId = "india",
  categoryId = "",
) => {
  const response = await InterceptorClient.getFTSInstance().get(
    `${UPDATE_PRODUCT_DETAILS_URL(productId, currencyUomId, catalogId, categoryId)}`,
    false,
  );
  return response;
};

/**
 * Fetches get Customer Review details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the getCustomerReviewAPI details.
 */
export const getCustomerReviewAPI = async (
  productId = "",
  domainId = constants.DOMAIN_ID,
  NoOfReviewRequest = constants.NoOfReviewRequest,
) => {
  const res = await InterceptorClient.getGatewayInstance().get(
    `${GET_PRODUCT_REVIEWS_URL(productId, domainId, NoOfReviewRequest)}`,
    false,
  );
  return res;
};

/**
 * Fetches get Recent ViewedByYou details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the getRecentViewedByYouAPI details.
 */
export const getRecentViewedByYouAPI = async (productsList) => {
  const productsListString = Array.isArray(productsList) ? productsList.join(",") : productsList;

  const res = await InterceptorClient.getFTSInstance().get(
    `${GET_RECENTLY_VIEWED_BY_YOU_URL(productsListString)}`,
    false,
  );
  return res;
};

/**
 * Fetches you May Aldo Like details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the youMayAlsoLikeAPI details.
 */
export const youMayAlsoLikeAPI = async (NoOfProductRequest, productId, catalogId = COUNTRIES.INDIA) => {
  const res = await InterceptorClient.getFTSInstance().get(
    `${GET_YOU_MAY_ALSO_LIKE_URL(NoOfProductRequest, productId, catalogId)}`,
    false,
  );
  return res;
};

/**
 * Fetches what Other Are Viewing details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the whatOthersAreViewing details.
 */
export const whatOthersAreViewingAPI = async (catalogId = COUNTRIES.INDIA, isRakhiProduct, productId) => {
  const res = await InterceptorClient.getFTSInstance().get(
    `${WHAT_OTHERS_ARE_VIEWING_URL(catalogId, isRakhiProduct, productId)}`,
    false,
  );
  return res;
};

/**
 * Fetches offer details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the offerData details.
 */
export const offerDataAPI = async (isMobile) => {
  const res = await InterceptorClient.getGatewayInstance().get(`${OFFERS_DATA_URL(isMobile)}`, false);
  return res;
};

/**
 * Upload Images to AWS S3 Bucket.
 * @returns {Promise<unknown>} A promise that return presigned url.
 */
export const uploadToAwsS3Bucket = async (fileName) => {
  const response = await InterceptorClient.getFTSInstance().get(`${AWS_PRE_SIGNED_URL(fileName)}`, false);
  return response;
};

/**
 * Get Content for Customization of single image
 * @returns {Promise<unknown>} HTML for the customization of product.
 */
export const getCustomizableImageContent = async (params) => {
  const response = await InterceptorClient.getFNTSInstance(webBaseUrl).get(
    `${CUSTOMIZABLE_CONTENT_URL(params)}`,
    false,
  );
  return response;
};

/**
 * Fetches analytics data from the analytics data API.
 *
 * @returns {Promise<Object>} Returns a promise that resolves to the response
 *                            from the analytics data API.
 */
export const analyticsDataAPI = async (isMobile) => {
  return await InterceptorClient.getGatewayInstance().get(`${ANALYTICS_DATA_URL()}`, false, null, {
    "x-device-type": isMobile ? "mobile" : "desktop",
    "x-domain": "www.fnp.com",
  });
};

/**
 * Fetches breadcrumb details from the server.
 *
 * @param {Object} params - The parameters to include in the request URL.
 * @returns {Promise<Object>} A promise that resolves to the breadcrumb details.
 */
export const getBreadCrumbs = async (params) => {
  const res = await InterceptorClient.getFNTSInstance(process.env.NEXT_PUBLIC_BASE_URL).get(
    `${GET_BREADCRUMBS_DETAILS(params)}`,
    false,
  );
  return res?.data;
};
/**
 * Fetches the calendar footer message from the API.
 *
 * @async
 * @function
 * @returns {Promise<Object>} A promise that resolves to the response object containing the calendar footer message.
 */
export const fetchCalendarFooterMsg = async () => {
  return await InterceptorClient.getGatewayInstance().get(`${CALENDER_MSG_URL()}`, false);
};

/**
 * Fetches product stock available details from the API.
 * @returns {Promise<unknown>} A promise that resolves to the product Details.
 */
export const getProductStockAvailableAPI = async (productId = "", isServer = false) => {
  const res = await InterceptorClient.getFTSInstance(isServer ? serverBaseUrl : "").get(
    `${GET_PRODUCT_STOCK_AVAILABLE_URL(productId)}`,
    false,
  );
  return res;
};

/**
 * Fetches the out of stock redirection urls from the API.
 * @async
 * @function
 * @returns {Promise<Object>} A promise that resolves to the response object containing the urls.
 */
export const getOutOfStockRedirectionUrlsAPI = async () => {
  return await InterceptorClient.getGatewayInstance().get(`${OUT_OF_STOCK_URL()}`, false);
};

/**
 * Fetches the get Price Surge Details from the API.
 * @async
 * @function
 * @returns {Promise<Object>} A promise that resolves to the response object containing the urls.
 */
export const getSurgePriceDetails = async (productId) => {
  return await InterceptorClient.getFTSInstance(process.env.NEXT_PUBLIC_BASE_URL).get(
    `${PRICE_SURGE_DETAILS_ENDPOINT(productId)}`,
    false,
  );
};
/**
 * Fetches the get Price Surge Details from the API.
 * @async
 * @function
 * @returns {Promise<Object>} A promise that resolves to the response object containing the urls.
 */
export const getPDPFooterLinks = async () => {
  return await InterceptorClient.getGatewayInstance().get(`${PDP_FOOTER_LINKS()}`, false);
};
