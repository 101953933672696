import { put, select, takeLatest } from "redux-saga/effects";
import { addAddonToCart, fetchAddOnInfo } from "../../../api/common/addOn";
import {
  requestToFetchAddOnInfoAction,
  successToFetchAddOnInfoAction,
  failToFetchAddOnInfoAction,
  addOnModalVisibility,
  addAddonToCartAction,
  successAddonToCartAction,
  failAddonToCartAction,
} from "../../slices/common/addOn";
import { requestViewCart, setSelectedCartProduct, setIsCartVisible } from "../../slices/cartSlice";
import { resetMultiProductDateRange, setDeliveryDetailsAction } from "../../slices/common/shippingDateAndSlotSelection";
import isEmpty from "lodash.isempty";
import { BUY_NOW } from "../../../constants/common/common";

/**
 *
 * @param {*} action
 */
function* getAddOnInfoSaga({ payload }) {
  try {
    const response = yield fetchAddOnInfo(payload);
    yield put(successToFetchAddOnInfoAction(response));

    if (isEmpty(response?.addonsList?.[0]?.products)) {
      yield put(requestViewCart());
      yield put(setIsCartVisible(true));
      yield put(setDeliveryDetailsAction({}));
    } else {
      yield put(addOnModalVisibility(true));
    }
  } catch (error) {
    yield put(failToFetchAddOnInfoAction(error));
    yield put(addOnModalVisibility(false));
  }
}

/**
 *
 * @param {*} action
 */
function* addAddonToCartSaga({ payload }) {
  const isBuyNowClicked = yield select((state) => state.pdp?.checkBuyNowOrProceedToPay);
  try {
    yield addAddonToCart(payload);
    yield put(setDeliveryDetailsAction({}));
    yield put(resetMultiProductDateRange());
    if (!(isBuyNowClicked === BUY_NOW)) {
      yield put(requestViewCart());
      yield put(setIsCartVisible(true));
      yield put(addOnModalVisibility(false));
    }
    yield put(successAddonToCartAction());
    yield put(setSelectedCartProduct({}));
  } catch (error) {
    yield put(failAddonToCartAction());
  }
}

/**
 * Description placeholder
 *
 * @export
 * @returns {{}}
 */
export function* watcherAddOnSaga() {
  yield takeLatest(requestToFetchAddOnInfoAction.type, getAddOnInfoSaga);
  yield takeLatest(addAddonToCartAction.type, addAddonToCartSaga);
}
