import { InterceptorClient } from "../../interceptor/interceptorClient";
import {
  FETCH_COUNTRY_LISTS,
  FETCH_EXISTING_USER,
  FETCH_TNC,
  GET_CUSTOMER_DETAILS,
  GET_GOOGLE_RESPONSE,
  INITIATE_CSRF_TOKENS,
  INITIATE_GHP,
  SEND_OTP,
  USER_SIGN_UP_ENDPOINT,
  VERIFY_OTP,
} from "./constants";

const apiBaseUrl = process.env.NEXT_PUBLIC_R2_CLIENT_API_GATEWAY;
const baseUrl = process.env.NEXT_PUBLIC_BASE_URL;

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
};

/**
 * Send OTP to the user's mobile or email.
 * @param {string} email - The email of the user.
 * @param {string} deviceType - The type of device (e.g., mobile, desktop).
 * @param {string} [mobile] - The mobile number of the user (optional).
 * @param {string} [countryCode] - The country code of the user's mobile number (optional).
 * @returns {Promise<Object>} - The response data.
 */
export const sendOTP = async (params) => {
  try {
    return await InterceptorClient.getFNTSInstance(baseUrl).post(params, `${SEND_OTP()}`, false, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Verify the OTP sent to the user.
 * @param {string} otp - The OTP sent to the user.
 * @param {string} email - The email of the user.
 * @returns {Promise<Object>} - The verification response data.
 */
export const verifyOTP = async (otp, email, phone) => {
  const params = { vc: otp, ...(email === null ? { mn: phone } : { un: email }) };
  const urlParams = new URLSearchParams(params).toString();

  try {
    return await InterceptorClient.getFNTSInstance(baseUrl).post(urlParams, `${VERIFY_OTP()}`, false, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Sends a user sign-up request to the API.
 *
 * @async
 * @param {string} token - The login token for authentication.
 * @param {string} userName - The username for the new user.
 * @param {string} firstName - The first name of the user.
 * @param {string} countryCode - The country code associated with the user's mobile number.
 * @param {string} mobileNumber - The user's mobile contact number.
 * @returns {Promise<Object>} A promise that resolves to the JSON response from the API.
 * @throws Will log an error if the API request fails.
 */
export const userSignUp = async (token, userName, countryCode, phoneNumber, email) => {
  try {
    const res = await fetch(`${baseUrl}${USER_SIGN_UP_ENDPOINT()}`, {
      method: "POST",
      headers: headers,
      credentials: "include",
      body: new URLSearchParams({
        logintoken: token,
        USER_FIRST_NAME: userName.trim(),
        countryCode: countryCode,
        CUSTOMER_MOBILE_CONTACT: phoneNumber,
        salutation: "Post",
        create_allow_password: false,
        requirePasswordChange: "N",
        un: email,
      }).toString(),
    });
    return await res.json();
  } catch (error) {
    console.log(error);
  }
};
/**
 * Get the list of countries.
 * @returns {Promise<Object>} - The list of countries.
 */
export const getCountryLists = async () => {
  return await InterceptorClient.getGatewayInstance(apiBaseUrl).get(`${FETCH_COUNTRY_LISTS()}`, false);
};

/**
 * Get login TNC
 * @returns {Promise<Object>} - The TNC Json.
 */
export const getTNC = async () => {
  return await InterceptorClient.getGatewayInstance(apiBaseUrl).get(`${FETCH_TNC()}`);
};

/**
 * Initiate Ghp Call
 * @returns {Promise<Object>} - encryption key.
 */
export const getGhpCall = async () => {
  return await InterceptorClient.getFNTSInstance().get(`${INITIATE_GHP()}`, false);
};

/**
 * Initiate csrf token Call
 * @returns {Promise<Object>} - encryption key.
 */
export const getCsrfTokensApiCall = async () => {
  return await InterceptorClient.getFNTSInstance(baseUrl).get(`${INITIATE_CSRF_TOKENS()}`, false);
};
/**
 * Initiate csrf token Call
 * @returns {Promise<Object>} - encryption key.
 */
export const fetchExistingUserApiCall = async (params) => {
  const urlParams = new URLSearchParams(params);
  try {
    return await InterceptorClient.getFNTSInstance(baseUrl).post(urlParams, `${FETCH_EXISTING_USER()}`, false, {
      "Content-Type": "application/x-www-form-urlencoded",
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get the customer details.
 * @param {*} params
 * @returns
 */
export const getCustomerDetails = async () => {
  return await InterceptorClient.getFNTSInstance(baseUrl).get(`${GET_CUSTOMER_DETAILS()}`, false);
};

/**
 * One Tap login get call
 */
export const getGoogleResponseCall = async (jwtToken) => {
  const response = await InterceptorClient.getFTSInstance(baseUrl).get(`${GET_GOOGLE_RESPONSE()}`, false, null, {
    jwtToken,
  });
  return response;
};
